import clsx from "clsx";
import {graphql, Link, useStaticQuery} from "gatsby";
import {GatsbyImage} from "gatsby-plugin-image";
import * as React from "react";
import {Breadcrumb} from "../../components/Breadcrumb";
import {Period} from "../../components/Period";
import {Section} from "../../components/Section";
import {Seo} from "../../components/Seo";
import {generateBorderColor, generateRandomColor} from "../../util/colors";

const webpMimeType = "video/webm";

const Projects = () => {
    const data = useStaticQuery(graphql`
    {
      allGraphCmsProject(filter: { stage: { eq: PUBLISHED } }) {
        edges {
          node {
            id
            source
            screenshot {
              gatsbyImageData(placeholder: BLURRED, quality:70)
              alt
              mimeType
              url
            }
            writeUp
            demo
            description
            tools {
              ... on GraphCMS_Tool {
                tool
              }
            }
            title
          }
        }
      }
    }
  `);

    return (
        <>
            <Seo title="Projects" description="Check out some cool things I've created."/>
            <Breadcrumb activeLabel="Projects"/>
            <header className="py-10 text-center">
                <div className="max-w-3xl mx-auto">
                    <h1
                        className={clsx(
                            "text-lg font-semibold mb-2",
                            generateRandomColor(1)
                        )}
                    >
                        Projects
                    </h1>
                    <p className="mb-4 text-2xl md:text-4xl font-bold dark:text-gray-300">
                        Some cool stuff I'v made. Check out the rest on {" "}
                        <a
                            className="underline-decoration-blue text-blue-600 hover:text-blue-500 dark:hover:text-blue-600"
                            href="https://github.com/gregorygaines"
                            rel="noreferrer"
                            target="_blank"
                        >
                            Github
                        </a>
                        <Period/>
                    </p>
                </div>
            </header>

            <Section>
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-10 max-w-5xl justify-center mx-auto">
                    {data.allGraphCmsProject.edges.map((project, index) => {
                        project = project.node;
                        return (
                            <div
                                className={clsx(
                                    "bg-white shadow-xl rounded-md border-t-8 flex flex-col dark:bg-gray-900 w-80 mx-auto",
                                    generateBorderColor(index)
                                )}
                                key={project.title}
                            >
                                <div className="h-48 w-full flex pr-0 pb-0 bg-center bg-contain bg-no-repeat">
                                    {project.screenshot.mimeType !== webpMimeType ? (
                                        <GatsbyImage
                                            imgStyle={{objectFit: "contain"}}
                                            loading="lazy"
                                            className="object-contain h-auto mx-auto"
                                            alt={project.screenshot.alt}
                                            image={project.screenshot.gatsbyImageData}
                                        />
                                    ) : (
                                        <video
                                            className="object-contain h-auto mx-auto p-2"
                                            loop
                                            muted
                                            playsInline
                                            autoPlay
                                        >
                                            <source src={project.screenshot.url} type="video/webm"/>
                                        </video>
                                    )}
                                </div>
                                <div className="px-5 flex flex-col gap-5 mt-4">
                                    <div className="mb-auto">
                                        <div className="flex">
                                            <svg
                                                aria-hidden="true"
                                                className="mr-2 h-4 self-center fill-current dark:text-blue-500"
                                                role="img"
                                                viewBox="0 0 12 16"
                                            >
                                                <path
                                                    fillRule="evenodd"
                                                    d="M4 9H3V8h1v1zm0-3H3v1h1V6zm0-2H3v1h1V4zm0-2H3v1h1V2zm8-1v12c0 .55-.45 1-1 1H6v2l-1.5-1.5L3 16v-2H1c-.55 0-1-.45-1-1V1c0-.55.45-1 1-1h10c.55 0 1 .45 1 1zm-1 10H1v2h2v-1h3v1h5v-2zm0-10H2v9h9V1z"
                                                />
                                            </svg>
                                            <h2 className="text-md font-bold font-gray-900 dark:text-gray-200">
                                                {project.title}
                                            </h2>
                                        </div>
                                        <p
                                            className="mt-2 dark:text-gray-200"
                                            style={{fontSize: "16px"}}
                                        >
                                            {project.description}
                                        </p>
                                    </div>
                                </div>
                                <div className=" py-4 flex flex-col gap-5 mt-auto mx-auto">
                                    <div className="flex gap-2">
                                        {
                                            project.source && (
                                                <a
                                                    href={project.source}
                                                    target="_blank"
                                                    rel="noopener"
                                                    className="shadow w-full bg-blue-600 rounded sm:w-auto py-1 px-2 hover:bg-blue-800 dark:shadow-md text-white font-semibold text-sm"
                                                >
                                                    Source
                                                </a>
                                            )
                                        }
                                        {project.demo && (
                                            <a
                                                href={project.demo}
                                                target="_blank"
                                                rel="noopener"
                                                className="shadow w-full bg-cgreen rounded sm:w-auto py-1 px-2 hover:bg-green-600 dark:shadow-md text-white font-semibold text-sm"
                                            >
                                                Demo
                                            </a>
                                        )}
                                        {project.writeUp && (
                                            <Link
                                                to={`/blog${project.writeUp}`}
                                                className="shadow w-full bg-purple-500 rounded sm:w-auto py-1 px-2 hover:bg-purple-800 dark:shadow-md text-white font-semibold text-sm"
                                            >
                                                Article
                                            </Link>
                                        )}
                                    </div>
                                </div>
                                <div className="flex flex-wrap gap-1 px-5 pb-5">
                                    {project.tools.map((tool) => (
                                        <span
                                            key={tool.tool}
                                            className="rounded px-2 py-1 text-xs border dark:bg-gray-900 dark:text-gray-300"
                                        >
                      {tool.tool}
                    </span>
                                    ))}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </Section>
        </>
    );
};

export default Projects;
